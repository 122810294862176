html, body, #root, .App {
  padding: 0;
  margin: 0;
  height: 100%;
}

body {
  font-family: Georgia, "Times New Roman", "FangSong", "仿宋", STFangSong, "华文仿宋", serif;
  color: #333;
  width: 100%;
  background-color: #C4D7ED;
}

h1, h2, h4 {
  font-family: 'Rajdhani', sans-serif;
  margin: 0;
  font-weight: 500;
}

.content {
  margin-top: 3em;
}

.header, .main {
  width: 100%;
  position: relative;
}

.header {
  background-color: #375D81;
  height: 40%;
}

.header .container {
  position: absolute;
  bottom: 0px;
  margin: 0 1em;
}

.main .container {
  padding: 1em;
}

.brand {
  color: #C4D7ED;
  font-size: 500%;
}

.copy {
  padding-top: 2em;
}

@media (max-width: 600px) {
  .header {
    height: 20%;
  }

  .brand {
    font-size: 400%;
  }
}

table {
  border-spacing: 0px;
}

td {
  padding: 0;
}

td~td {
  padding-left: 1em;
}

ul {
  list-style: square;
  margin: 0 0 2em 0;
  margin-left: -1em;
}

@media (min-width: 800px) {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }


  li {
    display:inline;
    padding-right: 1em;
  }

  li~li {
    padding-left: 1em;
    border-left: 1px solid #333;
  }
}
